<template>
  <div class="Help usinghelp">
    <div class="header">
      <div class="header-text">
        <h2>{{getString(Strings.Usinghelp)}}</h2>
      </div>
    </div>
    <div class="help-box">
      <div class="help-content">
        <div class="loading">
          <div class="loading-button">
            <ul class="lb-ul">
              <li
                v-for="(item, index) in data"
                :key="index"
                class="shipin-list"
                :class="item.src == gifSrc ? 'shipin-list-active' : ''"
              >
                <a href="javascript:;" @click="clickGif(item)">
                  <p class="sl-img">
                    <img
                      v-if="item == gifSrc"
                      src="../../assets/img/gifFileImg/checkGif.png"
                      alt=""
                    />
                    <img
                      v-else
                      src="../../assets/img/gifFileImg/stop1410.png"
                      alt=""
                    />
                  </p>
                  <p class="sl-zi">{{ getString(item.name) }}</p>
                </a>
              </li>
            </ul>
          </div>
          <div class="loading_image">
            <!-- <img v-if="gifSrc != ''" :src="gifSrc" /> -->
            
            <!-- <video v-if="gifSrc != ''" controls>
              <source :src="gifSrc.srcEn" :poster='gifSrc.posterEn'>
            </video> -->
            <video ref="video" controls controlslist="nodownload" autoplay
              preload="none" :poster="gifSrc.posterEn" :src="gifSrc.srcEn">
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import strings from '../../common/lang/strings';

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer, remote, app } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}

export default {
  data() {
    return {
      data: [
        // {
        //   name: strings.Mind_Usinghelp_2,
        //   srcEn:require("../../assets/img/Usehelp-English/3-DE-AddChildNode-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/3-DE-AddChildNode-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_4,
        //   srcEn:require("../../assets/img/Usehelp-English/4-DE-link-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/4-DE-link-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_5,
        //   srcEn:require("../../assets/img/Usehelp-English/5-DE-SetCanvas-Web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/5-DE-SetCanvas-Web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_6,
        //   srcEn:require("../../assets/img/Usehelp-English/6-DE-BeautifyText-Web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/6-DE-BeautifyText-Web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_7,
        //   srcEn:require("../../assets/img/Usehelp-English/7-DE-InsertRemarkLink-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/7-DE-InsertRemarkLink-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_17,
        //   srcEn:require("../../assets/img/Usehelp-English/8-DE-Undo+Undo-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/8-DE-Undo+Undo-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_18,
        //   srcEn:require("../../assets/img/Usehelp-English/9-DE-DragNodeMoveLocation-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/9-DE-DragNodeMoveLocation-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_19,
        //   srcEn:require("../../assets/img/Usehelp-English/10-DE-MultipleFreeNodeAlignment-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/10-DE-MultipleFreeNodeAlignment-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_20,
        //   srcEn:require("../../assets/img/Usehelp-English/11-DE-TransformMapType-Web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/11-DE-TransformMapType-Web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_21,
        //   srcEn:require("../../assets/img/Usehelp-English/12-DE-SetLineStyles-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/12-DE-SetLineStyles-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_22,
        //   srcEn:require("../../assets/img/Usehelp-English/13-DE-SetBackgroundColor-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/13-DE-SetBackgroundColor-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_23,
        //   srcEn:require("../../assets/img/Usehelp-English/14-DE-SetNodeShape-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/14-DE-SetNodeShape-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_24,
        //   srcEn:require("../../assets/img/Usehelp-English/15-DE-SetLineColorWidth-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/15-DE-SetLineColorWidth-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_25,
        //   srcEn:require("../../assets/img/Usehelp-English/16-DE-InsertImageAndSet-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/16-DE-InsertImageAndSet-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_26,
        //   srcEn:require("../../assets/img/Usehelp-English/17-DE-InsertIconAndSet-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/17-DE-InsertIconAndSet-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_27,
        //   srcEn:require("../../assets/img/Usehelp-English/18-DE-MultipleInsertSummaryDescription-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/18-DE-MultipleInsertSummaryDescription-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_28,
        //   srcEn:require("../../assets/img/Usehelp-English/19-DE-SingleNodeIntoBlock-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/19-DE-SingleNodeIntoBlock-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_29,
        //   srcEn:require("../../assets/img/Usehelp-English/20-DE-MultipleIntoBlocks-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/20-DE-MultipleIntoBlocks-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_30,
        //   srcEn:require("../../assets/img/Usehelp-English/21-DE-AddPersonBorderSingles-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/21-DE-AddPersonBorderSingles-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_31,
        //   srcEn:require("../../assets/img/Usehelp-English/22-DE-AddStatisticsChart-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/22-DE-AddStatisticsChart-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_32,
        //   srcEn:require("../../assets/img/Usehelp-English/23-DE-HideNode-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/23-DE-HideNode-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_33,
        //   srcEn:require("../../assets/img/Usehelp-English/24-DE-MultiSynchronousOperation-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/24-DE-MultiSynchronousOperation-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_34,
        //   srcEn:require("../../assets/img/Usehelp-English/25-DE-OneClickStyle-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/25-DE-OneClickStyle-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_35,
        //   srcEn: require("../../assets/img/Usehelp-Chinese/31-DE-HowInsertSpeechIntoWholeMap.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/31-DE-HowInsertSpeechIntoWholeMap.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_36,
        //   srcEn:require("../../assets/img/Usehelp-English/26-DE-ExportImages-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/26-DE-ExportImages-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_37,
        //   srcEn:require("../../assets/img/Usehelp-English/27-DE-exportPDF-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/27-DE-exportPDF-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_38,
        //   srcEn: require("../../assets/img/Usehelp-Chinese/32-DE-HowToPrintAmapDirectly.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/32-DE-HowToPrintAmapDirectly.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_39,
        //   srcEn:require("../../assets/img/Usehelp-English/28-DE-ShareLinks-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/28-DE-ShareLinks-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_40,
        //   srcEn: require("../../assets/img/Usehelp-Chinese/33-DE-HowShareMapsWithStorm.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/33-DE-HowShareMapsWithStorm.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_41,
        //   srcEn:require("../../assets/img/Usehelp-English/29-DE-NewFolder-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/29-DE-NewFolder-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_42,
        //   srcEn:require("../../assets/img/Usehelp-English/30-DE-DeleteMapRestore-web.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-English/thumbnailes/30-DE-DeleteMapRestore-web.png"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_43,
        //   srcEn:require("../../assets/img/Usehelp-Chinese/34-DE-HowDoFriendsApplyToJoinAGroup.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/34-DE-HowDoFriendsApplyToJoinAGroup.jpg"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_44,
        //   srcEn:require("../../assets/img/Usehelp-Chinese/35-DE-HowToDissolveAGroup.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/35-DE-HowToDissolveAGroup.jpg"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_45,
        //   srcEn: require("../../assets/img/Usehelp-Chinese/36-DE-HowToUploadFiles.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/36-DE-HowToUploadFiles.jpg"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_46,
        //   srcEn:require("../../assets/img/Usehelp-Chinese/37-DE-HowToAgreeToJoinTheApplication.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/37-DE-HowToAgreeToJoinTheApplication.jpg"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_47,
        //   srcEn: require("../../assets/img/Usehelp-Chinese/38-DE-HowToCoEdit.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/38-DE-HowToCoEdit.jpg"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_48,
        //   srcEn:require("../../assets/img/Usehelp-Chinese/39-DE-HowToCreateNewSharedGroup.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/39-DE-HowToCreateNewSharedGroup.jpg"),
        //   type:'video',
        // },
        // {
        //   name: strings.Mind_Usinghelp_49,
        //   srcEn:require("../../assets/img/Usehelp-Chinese/40-DE-HowToInviteGroupMembers.mp4"),
        //   posterEn:require("../../assets/img/Usehelp-Chinese/ThumbnailC/40-DE-HowToInviteGroupMembers.jpg"),
        //   type:'video',
        // },
      ],
      // gifSrc:{
      //     name: strings.Mind_Usinghelp_15,
      //     srcEn:require("../../assets/img/Usehelp-English/2-DE-NewMap-Web.mp4"),
      //     posterEn:require("../../assets/img/Usehelp-English/thumbnailes/2-DE-NewMap-Web.png"),
      //     type:'video',
      //   },
      Strings: Strings,
      srcEn:"",
      posterEn:'',
    };
  },
  created() {
 
  },

  methods: {
    getString(i) {
      return getString(i);
    },
    clickGif(item) {
      this.gifSrc = item;
      // console.log(item);
      this.srcEn = item.srcEn;
      this.posterEn = item.posterEn;
    },
  },
};
</script>


<style scoped>
ul li {
  list-style: none;
}
a {
  text-decoration: none;
}
.usinghelp {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding-left: 20px;
  padding-right: 20px;
}
.header-text h2 {
  left: 0px;
  font-size: 20px;
  font-family: Noto Sans SC;
  font-weight: bold;
  color: #333;
}
.help-content {
  padding: 15px 0px;
  /* border: 1px #ddd solid; */
}
.loading-button .lb-ul {
  padding-left: unset;
}
.lb-ul {
  display: flex;
  flex-wrap: wrap;
}
.lb-ul .shipin-list {
  height: 46px;
  width: 182px;

  display: flex;
  margin-bottom: 1em;
  margin-right: 1.5em;
  font-size: 14px;
}
.lb-ul .shipin-list:hover a {
  background-color: rgba(0, 0, 0, 0.1);
}
.loading-button .shipin-list a {
  display: flex;
  width: 100%;
  background: #f0f2f8;
  /* border: 2px solid #F0F2F8; */
  border-radius: 6px;
  color: #666666;
  font-size: 14px;
}
.loading-button .shipin-list a:focus {
  color: #fd492b;
  background: #ffffff;
  border: 2px solid #ff765e;
  opacity: 1;
  border-radius: 6px;
}
.loading-button .shipin-list a:focus .sl-img {
  margin-top: -2px;
  background: #ff765e;
  opacity: 1;
  border-radius: 6px 0px 0px 6px;
}

.lb-ul .shipin-list .sl-img {
  width: 48px;
  height: 46px;
  background: #d8d8d8;
  opacity: 1;
  border-radius: 6px 0px 0px 6px;
  text-align: center;
  padding-top: 8px;
  margin: unset;
}
.lb-ul .shipin-list .sl-zi {
  padding-left: 12px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 46px;
  opacity: 1;
}
.loading-button .shipin-list-active a {
  color: #fd492b;
  background: #ffffff;
  border: 2px solid #ff765e;
  opacity: 1;
  border-radius: 6px;
}
.loading-button .shipin-list-active a .sl-img {
  margin-top: -2px;
  background: #ff765e;
  opacity: 1;
  border-radius: 6px 0px 0px 6px;
}
.loading-button .shipin-list-active a .sl-zi {
  line-height: 44px;
}
.loading_image {
  text-align: center;
}
.loading_image img {
  width: 976px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 16px #ddd;
  max-width: 100%;
}
.loading_image video {
  width: 976px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 16px #ddd;
  max-width: 100%;
}
.loading_image video:focus{
  outline: none;
}
</style>